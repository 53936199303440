@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import '../../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-icons/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-richtexteditor/styles/material.css';


.btn {
  @apply w-full justify-center py-3 border border-transparent text-sm leading-5 font-medium rounded-md text-white transition duration-150 ease-in-out;
}

.btn:active {
  @apply bg-blue-700;
}

.btn:focus {
  @apply outline-none;
}

.btn-blue {
  @apply bg-blue-600;
}

.btn-blue:hover {
  @apply bg-blue-500;
}

.btn-blue:active {
  @apply bg-blue-700;
}

.btn-blue:focus {
  @apply border-blue-700 shadow-outline-blue;
}

.btn-gray {
  @apply bg-gray-200 text-red-500;
}

.btn-gray:hover {
  @apply text-red-600;
}

.btn-gray:active {
  @apply text-red-600;
}

.btn-red {
  @apply bg-red-700;
}

.btn-red:hover {
  @apply bg-red-600;
}

.btn-white {
  @apply text-gray-400 border border-gray-200;
}

.btn-white:hover {
  @apply text-gray-500;
}

.draggable-table-careerhighlight {
	position: relative;
  border-collapse: collapse;
  /* -webkit-box-shadow: 0px 0px 10px 8px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 0px 10px 8px rgba(0, 0, 0, 0.1);   */
  cursor: move; /* fallback: no `url()` support or images disabled */
  cursor: -webkit-grab; /* Chrome 1-21, Safari 4+ */
  cursor:    -moz-grab; /* Firefox 1.5-26 */
  cursor:         grab; /* W3C standards syntax, should come least */
  
  .draggable-table-careerhighlight__drag {
      font-size: .95em;
      /* font-weight: lighter; */
      text-transform: capitalize;    
      position: absolute;
      /* text-indent: 50px;     */
      border: 1px solid #9d0cb3;
      z-index: 10;
      cursor: grabbing;
      -webkit-box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.05);
              box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.05);
  }

  .is-dragging-careerhighlight {
    background-color: white;
  }
}

.textarea {
  resize: both; /* Allow both vertical and horizontal resizing */
  min-height: 100px; /* Set a minimum height */
  min-width: 320px; /* Set a minimum width */
  padding: 8px; /* Add padding for better aesthetics */
  border: 1px solid #ccc; /* Add a border */
  border-radius: 4px; /* Add border-radius for rounded corners */
  border-color: #C8C8C8;
  font-size: 14px; /* Set font size */
  line-height: 1.5; /* Set line height */
}

.editor {
  resize: both; /* Allow both vertical and horizontal resizing */
  min-height: 100px; /* Set a minimum height */
  min-width: 200px; /* Set a minimum width */
  padding: 8px; /* Add padding for better aesthetics */
  border: 1px solid #ccc; /* Add a border */
  border-radius: 4px; /* Add border-radius for rounded corners */
  border-color: #C8C8C8;
  font-size: 14px; /* Set font size */
  line-height: 1.5; /* Set line height */
  background-color: white;
}

.draggable-table-sponsorad {
	position: relative;
  border-collapse: collapse;
  /* -webkit-box-shadow: 0px 0px 10px 8px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 0px 10px 8px rgba(0, 0, 0, 0.1);   */
  cursor: move; /* fallback: no `url()` support or images disabled */
  cursor: -webkit-grab; /* Chrome 1-21, Safari 4+ */
  cursor:    -moz-grab; /* Firefox 1.5-26 */
  cursor:         grab; /* W3C standards syntax, should come least */
  
  .draggable-table-sponsorad__drag {
      font-size: .95em;
      /* font-weight: lighter; */
      text-transform: capitalize;    
      position: absolute;
      /* text-indent: 50px;     */
      border: 1px solid #9d0cb3;
      z-index: 10;
      cursor: grabbing;
      -webkit-box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.05);
              box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.05);
  }

  .is-dragging-sponsorad {
    background-color: white;
  }
}

.draggable-table-topupproduct {
	position: relative;
  border-collapse: collapse;
  /* -webkit-box-shadow: 0px 0px 10px 8px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 0px 10px 8px rgba(0, 0, 0, 0.1);   */
  cursor: move; /* fallback: no `url()` support or images disabled */
  cursor: -webkit-grab; /* Chrome 1-21, Safari 4+ */
  cursor:    -moz-grab; /* Firefox 1.5-26 */
  cursor:         grab; /* W3C standards syntax, should come least */
  
  .draggable-table-topupproduct__drag {
      font-size: .95em;
      /* font-weight: lighter; */
      text-transform: capitalize;    
      position: absolute;
      /* text-indent: 50px;     */
      border: 1px solid #9d0cb3;
      z-index: 10;
      cursor: grabbing;
      -webkit-box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.05);
              box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.05);
  }

  .is-dragging-topupproduct {
    background-color: white;
  }
}